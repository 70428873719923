<template>
    <div class="user">
        <div class="loading-con">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
    inject: ['reload'],
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        if (this.$route.query.access_token != undefined) {
            this.$post(this.URL.login.hvsign, {
                accessToken: this.$route.query.access_token
            }).then(res => {
                if (res.code == 0) {
                    localStorage.setItem('token', res.data.token);
                    //判断是不是要展示迁移提示弹窗
                    this.$store.commit('SET_HVUSERSHOW',res.data.isMigrationShow);
                    //判断是否完成迁移
                    this.$store.commit('SET_TRANSFERSHOW',res.data.isMigrationShow);
                    // if(!res.data.isMigrationShow){
                    //     //完成迁移了
                    //     this.$store.commit('SET_TRANSFERSHOW',false);
                    // }else{
                    //     //未完成迁移
                    //     this.$store.commit('SET_TRANSFERSHOW',true);
                    // }
                    //是否是三方登录
                    this.$store.commit('SET_ISHVUSER',true);

                    if(res.data.sourceType == 3 && res.data.account == ''){
                        localStorage.setItem('email',res.data.sourceEmail)
                        this.$router.push(`/bindname?type=1`)
                    }else {
                        this.getinfo()
                    }
                    
                    // if (res.data.email == '' && res.data.sourceType == 2) {
                    //     this.$router.push(`/bindhv?access_token=${this.$route.query.access_token}`)
                    // } 
                }
            }
            )
        } else {
          this.$router.push('/login');
        }
    },
    methods: {
        getinfo() {
            this.$post(this.URL.member.profile, {
            }).then((ress) => {
                if (ress.code == 0) {

                    this.$store.commit('SET_USERDATA', ress.data)
                    this.$store.commit('SET_LOGIN', true)
                    this.$store.commit("SET_USERINFO", ress.data);
                    this.$router.push('/home');
                    bus.emit('onLogin')
                    this.reload();
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.user {
    position: relative;
    min-height:550px;
    text-align: center;
    font-size: 20px;
    color: #111111;
}



.loading-con {
    position: absolute;
    top:50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%,-50%);
}

.loading-con>div {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    width: 50%;
    height: 20px;
    transform-origin: left center;
}

.loading-con>div::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    /* background-color: #000; */
    transform: var(--scale);
    animation: dotscale 1.3s linear infinite;
    animation-delay: var(--animation-delay);
}

.loading-con>div:nth-child(1) {
    transform: rotate(0deg);
    --animation-delay: 0s;
}

.loading-con>div:nth-child(2) {
    transform: rotate(30deg);
    --animation-delay: -0.1s;
}

.loading-con>div:nth-child(3) {
    transform: rotate(60deg);
    --animation-delay: -0.2s;
}

.loading-con>div:nth-child(4) {
    transform: rotate(90deg);
    --animation-delay: -0.3s;
}

.loading-con>div:nth-child(5) {
    transform: rotate(120deg);
    --animation-delay: -0.4s;
}

.loading-con>div:nth-child(6) {
    transform: rotate(150deg);
    --animation-delay: -0.5s;
}

.loading-con>div:nth-child(7) {
    transform: rotate(180deg);
    --animation-delay: -0.6s;
}

.loading-con>div:nth-child(8) {
    transform: rotate(210deg);
    --animation-delay: -0.7s;
}

.loading-con>div:nth-child(9) {
    transform: rotate(240deg);
    --animation-delay: -0.8s;
}

.loading-con>div:nth-child(10) {
    transform: rotate(270deg);
    --animation-delay: -0.9s;
}

.loading-con>div:nth-child(11) {
    transform: rotate(300deg);
    --animation-delay: -1s;
}

.loading-con>div:nth-child(12) {
    transform: rotate(330deg);
    --animation-delay: -1.1s;
}

img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}
@keyframes dotscale {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
  }

  100% {
    transform: scale(0);
    filter: hue-rotate(360deg);
  }
}
</style>